@use '../../../../../App.scss';

.side-menu {
    width: 80px;
    // background-color: #252525;
    background-color: #000;

    .side-menu-button {
        justify-content: center ;            
        margin-top: 6px;
        margin-bottom: 6px;    
        border: none;
        // border-radius: 4px;
    }
    .side-menu-button.active {
        border-left: 2px solid App.$primary-color;        
        border-right: 2px solid transparent;
    }
    .side-menu-button .MuiListItemIcon-root {
        justify-content: center;
    }
    .side-menu-button, .side-menu-button svg {
        font-size: 26px;
        color: #999;
    }
    .side-menu-button.active, .side-menu-button.active svg {
        color: white;
    }
}

.app-dark {
    .side-menu {
        background-color: #000;
    }
}