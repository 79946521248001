@use '../../../../../App.scss';

.bottom-nav {
    // background-color: #252525;
    background-color: #000;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    z-index: 1000;

    .nav-button {
        justify-content: center ;                    
        background-color: transparent;
        background: none;
        border-radius: 0;
        height: 60px;
        width: 60px;
        border: none;
        border-bottom: 2px solid transparent;
        border-top: 2px solid transparent;
        cursor: pointer;
    }
    .nav-button.active {
        border-top: 2px solid App.$primary-color;
    }
    .nav-button-label {
        font-size: 12px;
        color: #999;
    }
    .nav-button svg {
        font-size: 24px;
        color: #999;
    }
    .nav-button.active svg, .nav-button.active .nav-button-label {
        color: white;
    }
}

.app-dark {
    .bottom-nav {
        background-color: #000;
    }
}

.nav-drawer {
    background-color: App.$surface-2-light-bg-color;
    color: App.$text-color-light;

    svg {
        color: App.$text-color-light;
        font-size: 22px;
    }

    .nav-drawer-button {    
        justify-content: center;
        align-items: center;
        font-size: larger;
        div {
            display: inline-flex;
        }
        .icon {
            margin-right: 8px;           
        } 
        
        &.active {
            background-color: #00000015;
        }
    }


    &.dark {
        background-color: App.$surface-2-dark-bg-color;
        color: App.$text-color-dark;        
        svg {
            color: App.$text-color-dark;
        }

        .nav-drawer-button.active {
            background-color: #ffffff2f;
        }    
    }
}



