@use './../../../../App.scss';

.no-scrollbars::-webkit-scrollbar {
    width: 0;    
  }  
  @keyframes color {
    0% {
      background-color: #eee;
    }
    50% {
      background-color: #d8d8d8;      
    }
    100% {
      background-color: #eee;
    }
  }

  .timer-icon {
    animation-name: iconColor;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  @keyframes iconColor {
    0% {
      color: rgba(red, .9)
    }
    50% {
      color: rgba(red, .5)     
    }
    100% {
      color: rgba(red, .9)
    }
  }