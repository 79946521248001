.student-list-card {
    cursor: pointer;
      
    &:hover {
        transform: scale(1.02);    
        border-radius: 18px;        
    }
}

.tr-hover-grow {
    &:hover {
        .MuiAvatar-root {
            transform: scale(1.1);    
        }        
        cursor: pointer;                
        background-color: #aaaaaa33;
    }    
}

.hover-grow-avatar {
    &:hover {        
        .MuiAvatar-root {
            transform: scale(1.1);    
        }              
    }    
}

.hover-grow {
    &:hover {
        .MuiAvatar-root {
            transform: scale(1.1);    
        }        
        cursor: pointer;                
        background-color: #aaaaaa33;
        border-radius: 4px;
    }    
}