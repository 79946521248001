@use './../../../App.scss';
@use "sass:color";

.appButton {        
    text-transform: none;
    box-shadow: none;
    color: App.$text-color-light;
    border-radius: 20px;
    font-weight: 700;

    // svg {
    //     margin-right: 10px;
    // }

    &:hover {        
        scale: 1.01;
        box-shadow: none;
    }

    &.primary {        
        // background-color: App.$primary-color;        
        background-color: black;
        color: white;
        &:hover {
            // background-color: color.adjust(App.$primary-color, $blackness: 10%);;
            background-color: #1e1e1e;
        }
    }    
    &.neutral {
        background-color: #ddd;
        color: black;
        &:hover {
            background-color: color.adjust(#ddd, $blackness: 10%);;
        }
    }
    &.secondary {
        background-color: App.$secondary-color;
        &:hover {
            background-color: color.adjust(App.$secondary-color, $blackness: 10%);;
        }
    }
    &.error {
        background-color: App.$error-color;
        color: App.$error-text-color;
        &:hover {
            background-color: color.adjust(App.$error-color, $blackness: 10%);;
        }
    }    
}

// .app-dark {
//     .appButton {
//         &.neutral {
//             background-color: #777;
//             color: black;
//             &:hover {
//                 background-color: color.adjust(#777, $blackness: 10%);;
//             }
//         }
//     }
// }

