@use "sass:color";

// .app-light {
//   background-color: white;
//   color: black;  
// }
// .app-dark {
//   background-color: #555;
//   color: white;
// }

$primary-color: #47B5FF;
$primary-text-color: white;

$secondary-color: color.complement($color: $primary-color);

$surface-1-light-bg-color: #fff;
$surface-1-dark-bg-color: #202020;

$surface-2-light-bg-color: #e9e9e9;
$surface-2-dark-bg-color: #2e2e2e;

$text-color-light: #303030;
$text-color-dark: #fff;

$error-color: #d32f2f;
$error-text-color: white;


.test-gradient {
    background: linear-gradient(110deg, #15151599 0%, #252525 80%, #353535 100%);
}

.action-button-container {    
    .action-button {
        padding: 0;
        // display: none;
        visibility: hidden;
    }
    &:hover {
        .action-button {
            // display: block;
            visibility: visible;
        }
    }    
}

.pointer {
    cursor: pointer;
}

.hover-underline:hover {
    text-decoration: underline;
}

.color-error {
    color: #b37662
}
.color-success {
    // color: #63ba6a
    color: #07944e
}
.background-color-success {    
    background-color: #07944e
}

body {
    font-family: 'Inter';
}

.text-link {
    cursor: pointer;

    &:hover {
        font-size: 1.01em;
    }
}