.sub-menu {
    text-align: center;
    
    width: 140px;    
    background-color: #e9e9e9;    
    min-width: 150px;
    border-radius: 0px 30px 30px 0px;

    .sub-menu-button.active {
        background-color: #00000015;
    }
}

.toggle-theme-button {    
    .MuiListItemIcon-root {
        justify-content: center;
    }
    svg {
        color: #505050;
        font-size: 18px;
    }
}

.app-dark {    
    .sub-menu {
        background-color: #2e2e2e;            

        .sub-menu-button.active {
            background-color: #ffffff2f;
        }

        .toggle-theme-button {    
            svg {
                color: white;
            }
        }
    }
}